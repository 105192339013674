#service-hero {
  height: 250px;
  position: relative;
  overflow: hidden;
  background: #909396 url('#{$imagesPath}/loading-dark.gif') no-repeat center center;
  background-position: center center;

  @media(max-width: 767px) {
    height: 250px;
  }

  &.show {
    .inner-bg {
      opacity: 1;
    }
  }

  .inner-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-size: cover;
    background-position: center center;
    background-image: url('#{$customImagePath}/service_header.jpg');

    @include transition(opacity 0.5s);

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(32, 43, 55, 0.4) url('#{$imagesPath}/graphics/noise.png');
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    img {
      width: 0;
      height: 0;
      visibility: hidden;
      position: absolute;
    }
  }

  > section {
    position: relative;
    z-index: 999;

    @include transition(.8s);
    @include transform(scale(1.13));

    &.ready {
      @include transform(scale(1));
    }
  }

  h1 {
    color: #fff;
    font-size: 36px;
    font-weight: 400;
    text-align: center;
    position: relative;
    padding-top: 95px;
    margin: 0;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.14);
    letter-spacing: .3px;

    @media(max-width: 767px) {
      font-size: 30px;
      line-height: 40px;
      padding-top: 75px;
    }
  }

  p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    position: relative;
    padding-top: 10px;
    margin: 0;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.14);
    letter-spacing: .3px;

    @media(max-width: 767px) {
      font-size: 12px;
      line-height: 40px;
      padding-top: 10px;
    }
  }
}

.service-overview {
  margin-top: 20px;
  background: #fafafa;

  .container {
    padding: 30px 15px;
  }

  h1 {
    font-size: 18px;
    color: #0e467a;
    text-transform: uppercase;
    line-height: 17px;
    letter-spacing: 2px;
    font-weight: 600;
  }

  h4 {
    font-size: 30px;
    color: #4b4b4b;
    line-height: 36px;
    letter-spacing: 0;
    font-weight: 300;
    margin-top: 15px;
  }

  p {
    font-size: 15px;
    color: #7b8398;
    line-height: 27px;
    margin-top: 30px;
  }

  a {
    margin-top: 25px;

    i {
      padding-left: 5px;
    }
  }

  img {
    float: right;
    max-height: 250px;
    max-width: 100%;
  }

  @media(max-width: 768px) {
    img {
      width: 100%;
      max-height: initial;
      max-width: initial;
    }

    h1 {
      margin-top: 10px;
    }
  }
}

.service-detail {
  margin-top: 50px;

  h3 {
    /* Section Title */
    font-size: 18px;
    color: #0e467a;
    text-transform: uppercase;
    line-height: 17px;
    letter-spacing: 2px;
    font-weight: 600;
  }

  h4 {
    /* Large description text */
    font-size: 30px;
    color: #4B4B4B;
    line-height: 36px;
    letter-spacing: 0px;
    font-weight: 300;
    margin-top: 15px;
  }

  p {
    /* Small description text */
    font-size: 15px;
    color: #7B8398;
    line-height: 27px;
    margin-top: 30px;
  }

  img {
    width: 100%;
  }
}
