.callToAction {
  margin-top: 70px;
  padding: 30px 0;
  background: #fafafa;

  @media(max-width: 767px) {
    margin-top: 150px;
    margin-bottom: 0;
  }

  .cta {
    text-align: center;

    @media(max-width: 767px) {
      margin-top: 70px;
    }

    h3 {
      font-size: 29px;
      margin-top: 0;

      @media(max-width: 767px) {
        line-height: 35px;
        font-size: 26px;
      }
    }

    p {
      font-size: 17px;
      margin-top: 20px;
      color: #777;
    }

    a {
      margin-top: 25px;
      display: inline-block;
      background: #459CE7;
      color: #fff;
      padding: 13px 30px;
      border-radius: 50px;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-decoration: none;

      @include transition(all .2s);

      &:hover {
        background: #66B1F2;
      }
    }
  }

  #contactSuccess {
    margin-top: 30px;

    .alert {
      h5 { margin-bottom: 0; }
    }
  }
}

.start-project-form {
  form {
    width: 550px;
    margin: 40px auto 0;

    @media(max-width: 767px) {
      width: 100%;
    }

    h3 {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: .5px;
      margin-top: 40px;
    }

    p {
      margin-bottom: -5px;
      color: #5f748c;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      font-size: 17px;
      margin-top: 20px;
      outline: none;
      color: #666;
      background: white;
      padding: 18px 20px;
      border: 1px solid #cbcdd2;
      width: 100%;
      border-radius: 4px;

      @include transition(all .2s);

      @include placeholder {
        color: #B8BBC7;
      }

      @media(max-width: 767px) {
        padding: 15px 20px;
        font-size: 16px;
      }

      &:focus {
        border-color: #656565;

        @include placeholder {
          color: #666;
        }
      }
    }

    textarea {
      font-size: 16px;
    }

    div#recaptcha {
      margin-top: 23px;

      > div {
        margin: 0 auto;
      }
    }

    button[type="submit"] {
      background: linear-gradient(to bottom, #6fb1f5 0%, #3b97f7 100%);
      box-shadow: rgba(23, 43, 99, 0.24) 0 7px 28px;
      width: 100%;
      padding: 18px 20px;
      text-align: center;
      outline: none;
      border: none;
      margin-top: 23px;
      font-weight: 500;
      cursor: pointer;
      border-radius: 4px;
      font-size: 18px;
      color: #fff;
      text-shadow: 1px 1px 0px rgba(106, 132, 158, 0.6);

      @include transition(all .2s);

      &:hover {
        color: #fff;
      }
    }
  }
}