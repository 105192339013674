.testimonials {
  margin-top: 100px;
  background: #F9F9F9;
  padding-top: 50px;
  padding-bottom: 70px;
  border-top: 1px solid #F0F0F0;

  h4 {
    text-align: center;
    font-size: 19px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  hr {
    width: 50px;
    margin: 20px auto 25px;
    border-top: 2px solid #ccc;
  }

  h3 {
    margin-bottom: 70px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #444;
  }
}

.customer-testimonial {
  margin-top: 90px;

  @media(max-width: 767px) {
    margin-top: 60px;
  }

  .row {
    padding: 20px;
    padding-top: 70px;
    padding-bottom: 50px;
    border-top: 1px solid #f9f9f9;
    border-bottom: 1px solid #f9f9f9;

    @media(max-width: 767px) {
      padding-top: 50px;
    }
  }

  .customer-pic {
    width: 100px;
    border-radius: 100%;
    margin: 0 auto;
  }

  .logo {
    display: block;
    width: 105px;
    margin: 15px auto;

    @media(max-width: 991px) {
      width: 90px;
    }
  }

  .author {
    font-weight: 500;
    text-align: right;
    margin-right: 20px;
    position: relative;
    top: -5px;

    @media(max-width: 767px) {
      top: 0;
    }
  }

  p {
    font-size: 20px;
    position: relative;
    color: #777;
    line-height: 33px;

    @media(max-width: 991px) {
      font-size: 17px;
      line-height: 28px;
      margin-left: 20px;
    }

    @media(max-width: 767px) {
      margin-top: 30px;
      margin-left: 10px;
    }

    span {
      font-family: Georgia;
      font-size: 49px;
      position: absolute;
      left: -25px;
      top: 0px;
    }
  }
}