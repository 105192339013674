#careers-hero {
  height: 250px;
  position: relative;
  overflow: hidden;
  background: #909396 url('#{$imagesPath}/loading-dark.gif') no-repeat center center;
  background-position: center center;

  @media(max-width: 767px) {
    height: 500px;
  }

  &.show {
    .inner-bg {
      opacity: 1;
    }
  }

  .inner-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-size: cover;
    background-position: center center;
    background-image: url('#{$customImagePath}/career_header.jpg');

    @include transition(opacity 0.5s);

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    img {
      width: 0;
      height: 0;
      visibility: hidden;
      position: absolute;
    }
  }

  > section {
    position: relative;
    z-index: 999;

    @include transition(.8s);
    @include transform(scale(1.13));

    &.ready {
      @include transform(scale(1));
    }
  }

  h1 {
    color: #fff;
    font-size: 36px;
    font-weight: 400;
    text-align: center;
    position: relative;
    padding-top: 100px;
    margin: 0;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.14);
    letter-spacing: .3px;

    @media(max-width: 767px) {
      font-size: 30px;
      line-height: 40px;
      padding-top: 200px;
    }
  }
}

.careers-intro {
  margin-top: 70px;

  @media(max-width: 767px) {
    margin-top: 50px;
  }

  h3 {
    text-align: center;
    font-size: 27px;
    font-weight: 500;

    @media(max-width: 767px) {
      font-size: 25px;
    }
  }

  p {
    width: 74%;
    text-align: center;
    font-size: 15px;
    color: #555;
    line-height: 24px;
    margin: 20px auto 0;

    @media(max-width: 767px) {
      width: auto;
    }
  }
}

.careers-benefits {
  margin-top: 90px;
  height: 375px;

  @media(max-width: 991px) {
    height: auto;
  }

  @media(max-width: 767px) {
    margin-top: 60px;
  }

  .pic {
    float: left;
    width: 50%;
    background-image: url('#{$customImagePath}/career_img.jpg');
    background-color: #f9fafc;
    height: 100%;
    display: block;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    position: relative;

    @media(max-width: 1764px) {
      background-size: cover;
    }
  }

  .list {
    float: right;
    position: relative;
    width: 50%;
    height: 100%;
    background-color: #f9fafc;
    padding: 40px 100px 0 120px;

    @media(max-width: 991px) {
      float: none;
      width: auto;
      padding: 40px;
      text-align: center;
    }

    h3 {
      font-weight: 400;
      color: #4c4c4c;
      font-size: 24px;
    }

    ul {
      margin-top: 30px;
      padding-left: 20px;

      @media(max-width: 991px) {
        padding-left: 0;
      }

      li {
        margin-bottom: 12px;
        list-style-type: circle;
        font-size: 15px;
        color: #626a7b;

        @media(max-width: 991px) {
          list-style-type: none;
        }
      }
    }
  }
}

.careers-listing {
  margin-top: 70px;

  @media(max-width: 767px) {
    margin-top: 60px;
  }

  .title {
    text-align: center;
    margin-bottom: 25px;
    font-size: 25px;

    @media(max-width: 767px) {
      font-size: 23px;
    }
  }

  a {
    color: #0e467a;
  }

  .department {
    margin-bottom: 40px;

    @media(max-width: 767px) {
      text-align: center;
    }

    h4 {
      font-size: 21px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .role {
      margin-bottom: 20px;

      a {
        font-size: 16px;
      }

      .city {
        color: #555;
      }
    }
  }
}

.career-post-header {
  min-height: 380px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-image: url('#{$imagesPath}/unsplash/photo-1474843148229-3163319fcc4c.jpg');

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(85,93,107,.68);
  }

  section {
    padding-top: 160px;
    position: relative;
    z-index: 9;

    @media(max-width: 767px) {
      padding-top: 150px;
    }

    h1 {
      color: #fff;
      font-size: 33px;
      font-weight: 500 !important;
      text-align: center;
      letter-spacing: 1px;
      margin: 0 auto;
      line-height: 41px;

      @media(max-width: 767px) {
        font-size: 27px;
      }
    }

    p {
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      margin-top: 10px;

      @media(max-width: 767px) {
        font-size: 15px;
      }
    }
  }
}

.career-post-content {
  margin-top: 70px;

  @media(max-width: 767px) {
    margin-top: 40px;
  }

  > .container {
    max-width: 750px;
  }

  section {
    margin-bottom: 40px;
  }

  h4 {
    font-weight: 600;
    margin-bottom: 25px;
    font-size: 20px;
  }

  p {
    color: #444;
  }

  ul {
    padding-left: 20px;
    color: #444;

    li {
      margin-bottom: 5px;
    }
  }

  .apply-now {
    padding: 21px;
    border: 1px solid #e5e7ea;
    border-radius: 5px;
    text-align: center;
    margin-top: 55px;
    font-size: 16px;
    color: #454545;

    a {
      margin-left: 25px;

      @media(max-width: 767px) {
        display: block;
        margin: 25px 0 0;
      }
    }
  }
}