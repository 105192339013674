.aac-footer {
  margin-top: 200px;
  padding: 70px 0 20px;
  background: #0d3357;
  position: relative;

  @media(max-width: 767px) {
    margin-top: 130px;
    padding-bottom: 60px;
    text-align: center;
  }

  .col-md-3 {
    @media(max-width: 767px) {
      margin-bottom: 35px;
    }
  }

  .bottom {
    margin-top: 20px;
    border-top: 1px solid #6c80b8;
  }

  .go-top {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -25px;
    width: 50px;
    height: 50px;
    font-size: 12px;
    letter-spacing: 1px;
    background: #fff;
    padding-left: 2px;
    padding-top: 8px;
    border-radius: 50%;
    text-align: center;
    color: #222;
    text-decoration: none;

    i {
      display: block;
      font-size: 16px;
    }
  }

  h4 {
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 3px;
    font-family: $lato;
    font-size: 16px;
  }

  a.legal {
    color: #AFB9CB;
    display: block;
    margin-top: 35px;
    font-size: 14px;
    font-family: $merriweather;
    line-height: 28px;

    & + .legal {
      margin-top: 0px;
    }
  }

  p {
    color: #AFB9CB;
    margin-top: 35px;
    font-size: 14px;
    font-family: $merriweather;
    line-height: 28px;
  }

  .footer-contact {
    margin: 35px 0 16px 0;

    p {
      margin: 0;
    }
  }

  a.social {
    width: 30px;
    height: 30px;
    background: #BABFCD;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    margin-right: 12px;

    @include transition(all .2s);

    &:hover {
      background: #fff;
    }

    &.first {
      margin-top: 20px;
    }

    i {
      position: absolute;
      color: #0d3357;
      font-size: 24px;

      &.ion-social-facebook {
        top: -3px;
        left: 10px;
      }

      &.ion-social-linkedin {
        top: -4px;
        left: 6px;
      }

      &.ion-social-twitter {
        top: -3px;
        left: 4px;
      }
    }
  }
}