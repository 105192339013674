.home-slider-header {
  position: relative;
  top: -1px;

  .slider-component {
    height: 650px;

    .slide {
      &--center {
        text-align: center;
      }

      &--left {
        text-align: left;

        @media(max-width: 991px) {
          text-align: center;
        }
      }

      &--right {
        text-align: right;

        @media(max-width: 991px) {
          text-align: center;
        }
      }

      @media (min-width: 1200px) {
        .container {
            width: 950px;
        }
      }

      h2 {
        color: #fff;
        font-size: 37px;
        line-height: 45px;
        margin-top: 230px;
        font-weight: 400;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.17);

        @media(max-width: 991px) {
          font-size: 33px;
        }

        @media(max-width: 767px) {
          font-size: 27px;
          line-height: 35px;
        }
      }

      a {
        margin-top: 35px;
      }
    }

    .pic {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgba(82, 105, 128, 0.42);
      }
    }
  }
}

.home-hero {
  z-index: 5;
  margin-bottom: 20px;
  min-height: 400px;
  position: relative;
  background: #909396 url('#{$imagesPath}/loading-dark.gif') no-repeat center center;
  background-size: center;
  background-position: center center;

  @media(max-width: 767px) {
    background-position: center center;
    min-height: 270px;
  }

  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(85, 93, 107, 0.35);
  }

  .container-fluid {
    padding-left: 50px;
    padding-right: 50px;
  }

  h2 {
    color: #fff;
    font-size: 37px;
    line-height: 45px;
    top: 200px;
    font-weight: 400;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.17);

    @media(max-width: 991px) {
      font-size: 33px;
    }

    @media(max-width: 767px) {
      font-size: 27px;
      line-height: 35px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  a:hover, a:visited {
    color: inherit;
    text-decoration:none;
  }

  .pic {
    z-index: -2;
    display: block;
    min-width: 100%;
    max-width: none;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-size: cover; 
    background-position: center center;

    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: rgba(82, 105, 128, 0.42);
    }
  }

  .arrowHoverLeft > i {
    -webkit-transition: transform 0.25s ease-in;
    transition: transform 0.25s ease-in;
    display: inline-block;
  }

  .arrowHoverLeft:hover > i {
    -webkit-transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out;

    transform: translate(5px, 0);
  }
}

.home-intro {
  margin-top: 50px;

  h3 {
    text-align: center;
    font-size: 23px;
    letter-spacing: 2px;
    text-transform: uppercase;
    position: relative;
  }

  hr {
    width: 130px;
    margin: 35px auto;
    border-top: 2px solid #E4E4E4;
  }

  p {
    text-align: center;
    font-size: 16px;
    color: #454545;
    width: 65%;
    margin: auto;
    line-height: 29px;

    @media(max-width: 767px) {
      width: auto;
    }
  }
}

.home-intro-features {
  margin-top: 50px;
  text-align: center;

  @media(max-width: 767px) {
    margin-top: 90px;
  }

  .col-md-4 {
    margin-bottom: 60px;
  }

  i {
    display: inline-block;
    font-size: 54px;
    color: #4e94f1;
  }

  p {
    @media(max-width: 767px) {
      margin-bottom: 35px;
    }
  }
}

.home-services {
  margin-top: 50px;
  background: #fafafa;
  border-top: 1px solid #e2e1e1;
  border-bottom: 1px solid #e2e1e1;
  padding: 70px 0 40px;

  header {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: -50px;
      height: 1px;
      width: 87%;
      left: 0;
      right: 0;
      margin: auto;
      background: #eaeaea;
    }

    h3 {
      text-align: center;
      font-size: 26px;
      font-weight: 400;
      color: #555;

      @media(max-width: 767px) {
        font-size: 23px;
      }
    }

    p {
      color: #666;
      font-size: 15px;
      text-align: center;
      line-height: 25px;
      width: 75%;
      margin: 0 auto;
      margin-top: 30px;
    }
  }

  footer {
    position: relative;

    p {
      color: #666;
      font-size: 15px;
      text-align: center;
      line-height: 25px;
      width: 75%;
      margin: 0 auto;
      margin-top: 0px;

      i {
        padding-left: 5px;
      }
    }
  }

  .features {
    margin: auto;
    margin-top: 110px;
    width: 88%;

    .icon {
      font-size: 3rem;

      @media(min-width: 992px) {
        margin-right: 20px;
      }
    }
    .icon-linegraph {
      font-size: 2.5rem;
    }

    .feature {
      margin-bottom: 70px;

      @media(max-width: 767px) {
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
      }
    }

    section {
      width: 83%;
      float: right;

      @media(max-width: 991px) {
        width: 100%;
        margin-top: 20px;
        float: none;
      }

      h4 {
        margin-top: 0;
        font-size: 17px;
        color: #53575f;
      }

      p {
        margin-top: 18px;
        font-size: 15px;
        line-height: 24px;
        color: #666;
        width: 95%;
      }
    }
  }
}

.home-testimonial {
  margin-top: 90px;

  h3 {
    text-align: center;
  }

  p {
    text-align: center;
    margin-top: 30px;
  }

  i {
    padding-left: 5px;
  }

  footer {
    margin: 60px 0 40px;
    position: relative;

    p {
      color: #666;
      font-size: 15px;
      text-align: center;
      line-height: 25px;
      width: 75%;
      margin: 0 auto;
      margin-top: 0px;

      i {
        padding-left: 5px;
      }
    }
  }
}

.customFadeInLeft {
  -webkit-animation-name: customFadeInLeft;
  animation-name: customFadeInLeft;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;

  @include animationDuration(1s);
}

@include keyFrame(customFadeInLeft) {
  0% {
    opacity: 0;
    @include transform(translateX(-25px))
  }

  100% {
    opacity: 1;
    @include transform(none);
  }
}

.customFadeInRight {
  -webkit-animation-name: customFadeInRight;
  animation-name: customFadeInRight;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;

  @include animationDuration(1s);
}

@include keyFrame(customFadeInRight) {
  0% {
    opacity: 0;
    @include transform(translateX(25px))
  }

  100% {
    opacity: 1;
    @include transform(none);
  }
}
