.blog-category-header {
  padding: 32px 0 32px;
  width: 100%;
  background: #fbfbfb;
  z-index: 9;
  border-bottom: 1px solid #E4E4E4;

  &.sticky {
    @media(min-width: 992px) {
      position: fixed;
      top: 0;
      z-index: 99;
      border-bottom: 1px solid #E4E4E4;
    }
  }

  .title {
    margin: 0;
  }

  .categories {
    float: left;

    @media(max-width: 991px) {
      width: 60%;
    }

    @media(max-width: 767px) {
      display: none;
    }

    a {
      text-transform: uppercase;
      letter-spacing: 0.7px;
      margin-right: 35px;
      color: #4D4D4D;
      font-size: 12px;
      font-weight: 600;
      text-decoration: none;

      @include transition(all .1s);

      @media(max-width: 991px) {
        display: inline-block;
        margin-bottom: 10px;
      }

      &:hover {
        color: #4E8CC8;
      }
    }

    h5 {
      text-transform: uppercase;
      letter-spacing: 0.7px;
      margin-right: 35px;
      color: #4D4D4D;
      font-size: 12px;
      font-weight: 600;

      @media(max-width: 991px) {
        display: inline-block;
        margin-bottom: 10px;
      }
    }
  }

  .search {
    float: right;
    width: 243px;
    position: relative;
    top: -7px;

    form {
      position: relative;

      input[type="text"] {
        box-shadow: none;

        @include transform(scale3d(0,1,1));
        @include transformOrigin(100% 100%);
        @include transition(all .3s);

        &.opened {
          @include transform(scale3d(1,1,1));
        }
      }

      i.ion-search {
        font-size: 27px;
        color: #aaa;
        position: absolute;
        right: 10px;
        top: -1px;

        @include transition(color .3s);

        &:hover {
          color: #000;
        }
      }
    }
  }
}

.blog-posts-list {
  margin-bottom: 30px;

  > .container {
    @media (min-width: 992px) {
      width: 720px;
    }
  }

  .empty {
    margin: 20px 0;
  }

  .post {
    margin-top: 50px;

    .bg {
      width: 100%;
      height: 330px;
      margin-top: 25px;
      display: block;
      text-decoration: none;
      background-size: cover;
      background-position: center center;

      @media(max-width: 767px) {
        height: 280px;
      }
    }

    .title {
      font-size: 25px;
      font-weight: 500;
      color: #222;
      display: block;
      text-decoration: none;
    }

    .author {
      margin-top: 2px;
      color: #888;
      position: relative;

      .gravatar {
        position: absolute;
        top: -10px;
        left: -65px;
        width: 40px;
        height: 40px;
        border-radius: 50px;

        @media(max-width: 991px) {
          display: none;
        }
      }
    }

    .description {
      font-size: 15px;
      line-height: 24px;
      color: #555;
      margin-top: 25px;
    }

    .read-more {
      display: inline-block;
      margin-top: 15px;

      i {
        font-size: 12px;
        margin-left: 10px;
      }
    }
  }
}

.blog-post {
  margin-top: 50px;

  @media(max-width: 767px) {
    margin-top: 40px;
  }

  > .container {
    @media (min-width: 1200px) {
      width: 880px;
    }
  }
}

.blog-post-title {
  font-size: 31px;
  font-weight: 400;
  color: #444;

  @media(max-width: 991px) {
    font-size: 28px;
  }

  @media(max-width: 767px) {
    font-size: 24px;
    line-height: 35px;
  }
}

.blog-post-header {
  margin-top: 20px;
}

.blog-post-author {
  color: #888;
  display: inline-block;

  img {
    border-radius: 100%;
    width: 55px;
    height: 55px;
    margin-right: 17px;
    position: relative;
    top: -2px;
  }

  span {
    color: #333;
  }
}

.blog-post-date {
  display: inline-block;
  margin-left: 35px;
  color: #888;

  @media(max-width: 767px) {
    margin-left: 15px;
  }

  span {
    color: #333;
  }
}

.blog-post-share {
  float: right;
  color: #888;

  @media(max-width: 767px) {
    float: none;
  }

  .shariff {
    padding-top: 10px;

    .orientation-horizontal {
      flex-wrap: nowrap;
    }

    .theme-white {
      .shariff-button {
        border: none;
      }
    }
  }
}

.blog-post-content {
  margin-top: 50px;

  @media(max-width: 767px) {
    margin-top: 30px;
  }

  h1, h2, h3, h4, h5 {
    margin: 45px 0 20px;
    font-size: 23px;
  }

  p {
    font-size: 16px;
    color: #6b6b6b;
    line-height: 29px;
    margin-bottom: 20px;

    @media(max-width: 991px) {
      font-size: 14px;
    }
  }

  .blog-post-video {
    margin: 40px 0;

    iframe {
      width: 92%;
      display: block;
      margin: 0 auto;

      @media(max-width: 991px) {
        height: 350px;
      }

      @media(max-width: 767px) {
        width: 100%;
        height: 270px;
      }
    }
  }

  .blog-post-gallery {
    margin: 45px -20px;

    @media(max-width: 991px) {
      margin: 45px 0;
    }

    img {
      @media(max-width: 767px) {
        margin-top: 20px !important;
      }
    }

    .zoomerang {
      display: block;
    }
  }

  .blog-post-quote-wrapper {
    margin: 60px 0;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -25px;
      width: 3px;
      height: 100%;
      background: #e1e5ea;
    }

    .quote {
      font-family: $georgia;
      color: #444;
      font-style: italic;
      font-size: 21px;
      line-height: 32px;
      width: 95%;

      @media(max-width: 767px) {
        font-size: 18px;
        width: 100%;
      }
    }

    .author {
      margin-top: 15px;
      font-size: 15px;
      color: #9097a0;
      padding-bottom: 10px;
    }
  }
}

.app-blog {
  .pager {
    margin: 50px 0;
    text-align: center;

    a {
      background: #333;
      color: #fff;
      text-decoration: none;
      padding: 10px 18px;
      border-radius: 4px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 600;
      font-size: 13px;

      @include transition(all .2s);

      &:hover {
        background: #667B96;
      }
    }
  }
}