#about-hero {
  height: 250px;
  position: relative;
  overflow: hidden;
  background: #909396 url('#{$imagesPath}/loading-dark.gif') no-repeat center center;
  background-position: center center;

  @media(max-width: 767px) {
    height: 500px;
  }

  &.show {
    .inner-bg {
      opacity: 1;
    }
  }

  .inner-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-size: cover;
    background-position: center center;
    background-image: url('#{$customImagePath}/about_header.jpg');

    @include transition(opacity 0.5s);

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(32, 43, 55, 0.4) url('#{$imagesPath}/graphics/noise.png');
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    img {
      width: 0;
      height: 0;
      visibility: hidden;
      position: absolute;
    }
  }

  > section {
    position: relative;
    z-index: 999;

    @include transition(.8s);
    @include transform(scale(1.13));

    &.ready {
      @include transform(scale(1));
    }
  }

  h1 {
    color: #fff;
    font-size: 26px;
    font-weight: 400;
    text-align: center;
    position: relative;
    padding-top: 100px;
    margin: 0;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.14);
    letter-spacing: .3px;

    @media(max-width: 767px) {
      font-size: 16px;
      line-height: 40px;
      padding-top: 60px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.about-intro {
  margin-top: 60px;
  text-align: center;

  h2 {
    color: #48494B;
    letter-spacing: 1.5px;
    font-size: 22px;
    font-weight: 500;

    @media(max-width: 767px) {
      line-height: 32px;
    }
  }

  p {
    width: 720px;
    margin: 35px auto 0;
    font-size: 16px;
    color: #546579;
    line-height: 28px;

    @media(max-width: 767px) {
      width: auto;
      font-size: 16px;
    }
  }
}

.about-stats {
  margin-top: 90px;

  span {
    font-weight: 200;
    font-size: 50px;
  }

  p {
    font-weight: 500;
    margin-top: 10px;
  }
}