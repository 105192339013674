.badge {
  font-size: 80%;

  &.badge-outline {
    border: 1px solid;
    background: #fff;

    &.badge-primary {
      border-color: #0275d8;
      color: #0275d8;
    }

    &.badge-secondary {
      border-color: #868e96;
      color: #6a7177;
    }

    &.badge-success {
      border-color: #5cb85c;
      color: #5cb85c;
    }

    &.badge-info {
      border-color: #5bc0de;
      color: #5bc0de;
    }

    &.badge-warning {
      border-color: #f0ad4e;
      color: #f0ad4e;
    }

    &.badge-danger {
      border-color: #d9534f;
      color: #d9534f;
    }

    &.badge-light {
      border-color: #a9a9a9;
      color: #8a8a8a;
    }

    &.badge-dark {
      border-color: #222;
      color: #222;
    }
  }
}
